import React, { useState, useContext, useMemo } from 'react';

import { get } from 'lodash';
import { Select } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import AuthContext from '../../../context/AuthProvider';
import SelectArrowIcon from 'components/atoms/Icon/select-arrow';
import Icon from 'components/atoms/Icon/Icon';
import { bcLoginUrl } from 'helpers/bigcommerce';
import { useCallback } from 'react';
import * as pcStyles from './AccountPageMenu.module.css';
import * as mpsStyles from './AccountPageMenu.mps.module.css';
import { storeStyle, isMadPawsStore } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const externalLinks = ['/account.php?action=payment_methods', '/subscriptions'];
const routes = [
  {
    label: 'My Account',
    url: '/account/'
  },
  {
    label: 'Orders',
    url: '/account/orders'
  },
  {
    label: 'Wishlists',
    url: '/account/wishlists'
  },
  {
    label: 'Addresses',
    url: '/account/addresses',
    divider: true
  },
  {
    label: 'Payments',
    url: '/account.php?action=payment_methods',
    external: true
  },
  {
    label: 'Autoship',
    url: '/subscriptions',
    external: true,
    divider: true
  },
  {
    label: 'Log out',
    url: '/logout'
  }
];

const AccountPageMenu = ({ isLoggedIn }) => {
  const { pathname } = useLocation();
  const auth = useContext(AuthContext);

  const { customerId } = get(auth, 'state', {});
  const handleLogout = auth && auth.logout;
  const [clickedIndex, setClickedIndex] = useState(null);

  const redirectHandler = useCallback(
    url => {
      bcLoginUrl(customerId, url).then(url => {
        // console.log("Logged in", url);
        window.location = url;
      });
    },
    [customerId]
  );

  const menuItems = useMemo(() => {
    if (!isLoggedIn) {
      return [
        <Link to="/login" key="login">
          Login
        </Link>,
        <Link to="/signup" key="signup">
          Signup
        </Link>
      ];
    }
    return routes.map((route, index) => (
      <React.Fragment key={route.url}>
        {route.url === '/logout' ? (
          <Link
            to={route.url}
            style={{
              color: index === clickedIndex && 'var(--primary-default)'
            }}
            onClick={e => {
              e.preventDefault();
              navigate('/');
              setClickedIndex(index);
              handleLogout();
            }}
          >
            {route.label}
          </Link>
        ) : (
          <Link
            activeStyle={{ color: isMadPawsStore() ? 'var(--primary-default)' : 'var(--blue-06)' }}
            className={styles.external}
            style={{
              color: index === clickedIndex && 'var(--primary-default)'
            }}
            to={route.url}
            onClick={
              route.external
                ? e => {
                    e.preventDefault();
                    redirectHandler(route.url);
                    setClickedIndex(index);
                  }
                : undefined
            }
          >
            {route.label}
            {route.external && (
              <Icon symbol={isMadPawsStore() ? 'external-link-mps' : 'external-link'} />
            )}
          </Link>
        )}
        {route.divider && <div className={styles.divider} />}
      </React.Fragment>
    ));
  }, [isLoggedIn, handleLogout, redirectHandler]);

  return (
    <>
      <div className={styles.root}>
        {isLoggedIn && (
          <>
            <div className={styles.user}>Welcome back</div>
            <div className={styles.divider} />
          </>
        )}
        {menuItems}
      </div>

      <div className={styles.mobileMenu}>
        <Select
          rounded={'full'}
          onChange={e => {
            e.currentTarget.value === '/logout'
              ? handleLogout()
              : externalLinks.includes(e.currentTarget.value)
              ? redirectHandler(e.currentTarget.value)
              : navigate(e.currentTarget.value);
          }}
          className={styles.customSelect}
          icon={<SelectArrowIcon />}
          value={pathname}
        >
          {routes.map(route => (
            <option key={route.url} value={route.url}>
              {route.label}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default AccountPageMenu;
