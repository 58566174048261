import React from 'react';

const SelectArrowIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1969 4L6.99688 9.6L1.79688 4"
        stroke="#4B5661"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default SelectArrowIcon;
